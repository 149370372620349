<template>
  <div>
    <div class="d-flex justify-content-end pb-2">
      <div class="dropdown">
        <el-button type="primary" class="bg-violet border-0">
          Hành động
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill"
            viewBox="0 0 16 16">
            <path
              d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
          </svg>
        </el-button>
        <div class="dropdown-content rounded">
          <el-button @click="modalAdd" class="border-0 m-0" v-if="checkTrainer">Thêm mới ứng viên</el-button>
          <el-button @click="dialog = true" class="border-0 m-0">Điểm danh học đào tạo</el-button>
          <el-button @click="dialogAddMore = true" class="border-0 m-0" v-if="checkTrainer">Import dữ liệu</el-button>
          <el-button @click="exportExcelCandidate" class="border-0 m-0" v-if="checkTrainer">Export dữ liệu</el-button>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div id="panel-1" class="panel">
          <div class="panel-container show">
            <div class="panel-content">
              <el-row :gutter="10" class="mb-5" v-if="!checkStatusFilter">
                <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
                  <label for="" class="pb-sm-1">Ứng viên</label>
                  <el-input type="text" clearable size="medium" v-model="form.keyword" class="w-100 pb-2"
                    @input="getCandidate()" debounce="500" placeholder="Nhập mã, tên, SĐT, email"
                    autocomplete="false"></el-input>
                </el-col>

                <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4" class="">
                  <label for="" class="pb-sm-1">Trạng thái ứng viên</label>
                  <el-select v-model="form.status" class="w-100 pb-2" @change="getCandidate()" filterable clearable
                    multiple collapse-tags placeholder="Chọn trạng thái">
                    <el-option v-for="item in statusCandidate" :key="item.id" :label="item.label"
                      placeholder="Trạng thái ứng viên" :value="item.id">
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
                  <label for="" class="pb-sm-1">Văn phòng làm việc</label>
                  <el-select v-model="form.branch_id" class="w-100" filterable clearable multiple collapse-tags
                    @change="getCandidate()" placeholder="Chọn văn phòng">
                    <el-option v-for="item in listBranch" :key="item.id" :label="item.name_alias" placeholder="Chọn cơ sở"
                      :value="item.id">
                      <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="3">
                  <label for="" class="pb-sm-1">Thời gian thêm ứng viên</label>
                  <el-date-picker v-model="form.startDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="daterange"
                    align="right" start-placeholder="Từ ngày" end-placeholder="Đến ngày"
                    :picker-options="pickerStartOptions" @change="getCandidate" class="dateSize">
                  </el-date-picker>
                </el-col>
              </el-row>
              <div class="mb-4" v-if="checkStatusFilter">
                <span class="mr-3">Đã chọn( {{ checkboxProp.length }} )</span>
                <el-select v-model="candidateActionValue" @change="changeStatusCandidate">
                  <el-option v-for="item in listAction" :key="item.id" :label="item.label" placeholder="Chọn cơ sở"
                    :value="item.id">
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
                <el-button class="ml-2 bg-da" @click="closeCheckbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                  Hủy
                </el-button>
              </div>
              <!-- datatable start -->
              <div class="example-preview table-responsive overflow-auto mt-3">
                <table class="table text-left table-hover table-bordered table-vertical-center b-table">
                  <thead>
                    <tr>
                      <th scope="col" v-if="checkTrainer">
                        <input type="checkbox" @change="checkBoxAll" v-model="all" value="0">
                      </th>
                      <th scope="col">Ứng viên</th>
                      <th scope="col">Văn phòng</th>
                      <th scope="col">Trạng thái ứng viên</th>
                      <th scope="col">Thời gian thêm ứng viên</th>
                      <th scope="col" v-for="(item, index) in listCourse" :key="index">
                        {{ item.title }}
                      </th>
                      <th scope="col">Thời hạn học đào tạo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in listCandidate" :key="index">
                      <td v-if="checkTrainer">
                        <input type="checkbox" :value="item.candidate ? item.candidate.id : ''" v-model="checkboxProp"
                          @change="statusFilter">
                      </td>
                      <td>
                        <el-button type="text" @click="updateCandidate(item.candidate ? item.candidate.id : '')" class="">
                          {{
                            item.user ? 100000 + item.user.id + ' - ' + item.user.name : 'Không có dữ liệu'
                          }}
                        </el-button>
                      </td>
                      <td>
                        {{
                          item.branch ? item.branch.name_alias : 'Không có dữ liệu'
                        }}
                      </td>
                      <td>
                        {{
                          item.candidate ? listStatus(item.candidate.status).text : 'Không có dữ liệu'
                        }}
                      </td>
                      <td>{{ formatDateTimeVietnam(item.candidate ? item.candidate.created_at : '') }}</td>
                      <td v-for="(item_prop, index) in changeCourse(item.user.id)" :key="index">
                        <span v-if="item_prop">
                          <span class="rounded-circle px-2 mr-2 " :class="changeStatus(item_prop.status).color"></span> {{
                            changeStatus(item_prop.status).text}}
                        </span>
                        <span v-else></span>
                      </td>
                      <td>
                        <span class="bold p-2 rounded"
                          :class="deadlineCandidate(item.candidate ? item.candidate.deadline : '', item.candidate ? item.candidate.id : '').bg">
                          {{ deadlineCandidate(item.candidate ? item.candidate.deadline : '', item.candidate ?
                            item.candidate.id : '').data }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="!listCandidate.length" class="text-center">Không có dữ liệu.</div>
              <div class="d-flex justify-content-end bold">Tổng số bản ghi: {{ paging.total }}</div>
              <div class="edutalk-paging">
                <div class="block">
                  <!--eslint-disable-->
                  <el-pagination background @current-change="handleCurrentChange" :current-page.sync="paging.current_page"
                    :page-size="paging.per_page" layout="prev, pager, next" :total="paging.total">
                  </el-pagination>
                </div>
              </div>
              <!-- datatable end -->
            </div>
          </div>
        </div>
      </div>
      <el-dialog :title="!check ? 'Thêm mới ứng viên' : 'Cập nhập ứng viên'" :visible.sync="dialogVisible" width="70%">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form class="form" autocomplete="off">
            <h4>Thông tin ứng viên</h4>
            <div class="row">

              <div class="col-6 mt-2">
                <label>Số điện thoại:</label>
                <ValidationProvider vid="phone" name="phone" :rules="{}" v-slot="{ errors, classes }">
                  <el-input placeholder="Số điện thoại " v-model="dataTable.phone" :class="classes" maxlength="10"
                    type="number" :disabled="check" @change="phoneNumberChange()"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-6 mt-2">
                <label>Họ và tên:</label>
                <ValidationProvider vid="name" name="name" :rules="{}" v-slot="{ errors, classes }">
                  <el-input :disabled="check || checkUserExisted" placeholder="Nhập họ và tên" v-model="dataTable.name" :class="classes" maxlength="50" ></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-6 mt-2">
                <label>Khu vực:</label>
                <input type="text" :value="area.name" class="form-control" disabled>
              </div>
              <div class="col-6 mt-2">
                <label>Văn phòng:</label>
                <ValidationProvider vid="chi_nhanh_id" name="Ma gioi thieu" :rules="{}" v-slot="{ errors, classes }">
                  <el-select class="w-full" v-model="dataTable.chi_nhanh_id" :disabled="!checkTrainer">
                    <el-option v-for="item in branch" :key="item.id" :label="item.name_alias" :value="item.id">

                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-6 mt-2">
                <label>Loại ứng viên:</label>
                <ValidationProvider vid="type" name="Loai ung vien" :rules="{}" v-slot="{ errors, classes }">
                  <el-select class="w-full" v-model="dataTable.type" :disabled="check">
                    <el-option v-for="item in candidateTypes" :key="item.value" :label="item.label" :value="item.value">
                      <span style="float: left">
                        <b>{{ item.label }}</b>
                      </span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h4>Thông tin tài khoản nội bộ</h4>
              </div>
              <div class="col-12 mt-2">
                <label>Email đăng nhập: </label>
                <ValidationProvider vid="email" name="email" :rules="{}" v-slot="{ errors, classes }">
                  <el-input placeholder="Email" v-model="dataTable.email" :class="classes" maxlength="50"
                    :disabled="check || checkUserExisted"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-6 mt-2" v-if="dataUpdate">
                <label>Mật khẩu :</label>
                <ValidationProvider vid="password" name="mat khau" :rules="{}" v-slot="{ errors, classes }">
                  <el-input placeholder="Mật khẩu" v-model="dataTable.password" :class="classes" type="password"
                    show-password :disabled="!check && checkUserExisted"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-6 mt-2" v-if="dataUpdate">
                <label>Nhập lại mật khẩu :</label>
                <ValidationProvider vid="confirm_password" name="nhap lai mat khau" :rules="{}"
                  v-slot="{ errors, classes }">
                  <el-input placeholder="Nhập lại mật khẩu" v-model="dataTable.confirm_password" :class="classes"
                    type="password" show-password :disabled="!check && checkUserExisted"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div v-if="!dataUpdate" class="col-12">
                <el-button type="text" @click="updatePass = true, dataUpdate = true">Đặt lại mặt khẩu</el-button>
              </div>
            </div>
            <div class="d-flex dialog-footer justify-content-end mt-3">
              <el-button @click="dialogVisible = false">Hủy bỏ</el-button>
              <el-button v-if="!updatePass" type="submit" @click="hanldSubmit">Xác nhận</el-button>
              <el-button v-else type="submit" @click="hanldSubmitUpdate()">Xác nhận</el-button>
            </div>
          </form>
        </ValidationObserver>
      </el-dialog>
      <el-dialog title="Import dữ liệu" :visible.sync="dialogAddMore" width="75%">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form class="form" autocomplete="off">
            <div class="">
              <p>Bước 1: Tải file mẫu excel</p>
              <button type="button" class="btn bg-orange font-weight-bold mr-2 mb-3" @click="exportExcelExample">
                Xuất file mẫu
              </button>
            </div>
            <div class="">
              <p>Bước 2: Sau khi cập nhập dữ liệu tại file excel, tiến hành tải file lên hệ thống</p>

              <label for="upload-input" class="btn btn-primary font-weight-bold mr-2">
                Upload file
                <input type="file" id="upload-input" hidden @input="changeExcelExample" placeholder="Upload file"
                  ref="myFileInput">
              </label>
              <div class="mt-2">
                <span class="bg-da p-2 rounded" v-if="addFile">
                  {{ files_prop.name }}
                  <svg v-if="files_prop" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-x-circle-fill" viewBox="0 0 16 16" @click="closeFile">
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                </span>
              </div>
              <ValidationProvider vid="0.email_dang_nhap" name="phone" :rules="{}" v-slot="{ errors, classes }">
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="d-flex dialog-footer justify-content-end mt-3">
              <el-button @click="dialogAddMore = false">Hủy bỏ</el-button>
              <el-button type="submit" @click="importFile">Xác nhận</el-button>
            </div>
          </form>
        </ValidationObserver>
      </el-dialog>
      <el-dialog title="Xóa bản ghi" :visible.sync="dialogDeleteCandidate" width="30%">
        <p>Bạn chắc chắn muốn xóa <span class="bold">{{ this.checkboxProp.length }} bản ghi</span> đã chọn chứ ?</p>
        <p class="text-red">Lưu ý : Thao tác xóa sẽ khiến các bản ghi biến mất vĩnh viễn khỏi hệ thống .</p>
        <div class="d-flex dialog-footer justify-content-end mt-3">
          <el-button @click="dialogDeleteCandidate = false, candidateActionValue = ''">Hủy bỏ</el-button>
          <el-button type="submit" @click="deleteCandidate">Xác nhận</el-button>
        </div>
      </el-dialog>
      <el-dialog title="Đánh fail đào tạo" :visible.sync="dialogFailCandidate" width="30%">
        <p>Bạn chắc chắn muốn cho <span class="bold">{{ this.checkboxProp.length }} ứng viên đã chọn</span> Fail đào tạo
          chứ?</p>
        <div class="d-flex dialog-footer justify-content-end mt-3">
          <el-button @click="dialogFailCandidate = false, candidateActionValue = ''">Hủy bỏ</el-button>
          <el-button type="submit" @click="updateStatusCandidate">Xác nhận</el-button>
        </div>
      </el-dialog>
      <el-dialog title="Import dữ liệu" :visible.sync="logImport" width="70%">
        <p>Bước 2: Kiểm tra dữ liệu</p>
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form class="form" autocomplete="off">
            <div class="example-preview table-responsive overflow-auto mt-3">
              <table class="table text-left table-hover table-bordered table-vertical-center b-table">
                <thead>
                  <th scope="col">STT</th>
                  <th scope="col">Ứng viên</th>
                  <th scope="col">Số điện thoại</th>
                  <th scope="col">Email</th>
                  <th scope="col">Kết quả</th>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listDataImport" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.ho_va_ten ?? '' }}</td>
                    <td>{{ item.so_dien_thoai ?? '' }}</td>
                    <td>{{ item.email ?? '' }}</td>
                    <td>
                      <p v-if="item.success">
                        <span class="text-green">Đạt</span>
                      </p>
                      <p v-if="!item.success" v-for="(item_prop, index_prop) in item?.error?.filter(d => d != null)"
                        :key="index_prop">
                        <span class="text-red">{{ item_prop }}</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex dialog-footer justify-content-end mt-3">
              <el-button @click="cancelImport">Hủy bỏ</el-button>
              <el-button type="submit" 
              :disabled="loadingConfirm"
              @click="confirmImportData">
              <i v-bind:class="[loadingConfirm ? 'el-icon-loading' : '']"></i>
              Xác nhận</el-button>
            </div>
          </form>
        </ValidationObserver>
      </el-dialog>
      <el-dialog title="Điểm danh học đào tạo" :visible.sync="dialog" width="75%">
        <div>
          <div class="col-12 d-flex mb-3">
            <div class="col-6 p-0">
              <div>
                <label for="">Khóa học</label>
              </div>
              <el-select v-model="course" @change="getCandidataAttendance">
                <el-option v-for="item in listCourse" :key="item.id" :label="item.title" placeholder="Chọn cơ sở"
                  :value="item.id">
                  <span>{{ item.title }}</span>
                </el-option>
              </el-select>
              <div>
                <span class="text-red">{{ messageRequire }}</span>
              </div>
            </div>
            <div class="col-6 p-0">
              <div>
                <label for="">Chọn văn phòng</label>
              </div>
              <el-select v-model="filterbranch" filterable @change="getCandidataAttendance">
                <el-option v-for="item in listBranch" :key="item.id" :label="item.name_alias" placeholder="Chọn cơ sở"
                  :value="item.id">
                  <span style="float: left">{{ item.name_alias }}</span>
                </el-option>
              </el-select>
              <div>
                <span class="text-red">{{ messageRequireBranch }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="example-preview table-responsive overflow-auto mt-3">
              <table class="table text-left table-hover table-bordered table-vertical-center b-table">
                <thead>
                  <th scope="col">STT</th>
                  <th scope="col">Ứng viên</th>
                  <th scope="col">Văn phòng</th>
                  <th scope="col">Điểm danh</th>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in candidateAttendance" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.user ? 100000 + item.user.id + ' - ' + item.user.name : 'Không có dữ liệu' }}</td>
                    <td>{{ item.branch ? item.branch.name_alias : 'Không có dữ liệu' }}</td>
                    <td><input type="checkbox" :value="item.candidate ? item.candidate.id : ''"
                        v-model="checkboxAttendance" @change="changeAttendance"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-center" v-if="!checkData">Chưa có dữ liệu</div>
            <div>
              <span class="text-red">{{ messageerrorAttendance }}</span>
            </div>
            <div class="d-flex dialog-footer justify-content-end mt-3">
              <!--              <span class="d-flex justify-content-center  mr-3">-->
              <!--                <input type="checkbox" v-model="checkBoxClose" class="mr-2">-->
              <!--                <span class="py-3">-->
              <!--                  Đóng sau điểm danh-->
              <!--                </span>-->
              <!--              </span>-->
              <el-button type="submit" @click="attendance" class="bg-violet text-white p-2">Điểm danh</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { GET_AREA } from "@/core/services/store/service.module";
import { GET_BRANCHES_ALL, GET_BRANCHES_BY_AREAS } from "@/core/services/store/center/branch.module";
import { TRAINER, STUDENT } from "@/core/config/accountTypeOption";
import {
  CREATE_CANDIDATE,
  DELETE_CANDIDATE,
  EXPORT_EXCEL,
  EXPORT_EXCEL_CANDIDATE,
  GET_CANDIDATE,
  GET_CANDIDATE_ATTENDANCE,
  GET_CANDIDATE_BY_USER_ID,
  GET_DATA_IMPORT,
  GET_USER_LOGIN,
  IMPORT_EXCEL,
  UPDATE_CANDIDATE, UPDATE_FAIL,
  UPDATE_STATUS_CANDIDATE,
  CANDIDATE_TYPES
} from "@/core/services/store/user/users.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_LIST_COURSE,GET_USER_CANDIDATE, GET_LIST_COURSE_BY_AREA } from "@/core/services/store/course/courseLevel.module";
import { mapGetters, mapState } from "vuex";
import { XLSX } from "xlsx";
import { read, utils } from "xlsx";
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "danh-sach-ung-vien",
  data() {
    return {
      checkTrainer: true,
      addFile: false,
      loadingConfirm: true,
      form: {
        keyword: '',
        status: '',
        startDate: '',
        branch_id: '',
        is_debt: 1,
      },
      result: {
        tableData: null,
      },
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      pickerEndOptions: {
        disabledDate: this.disabledEndDate
      },
      listAction: [
        {
          id: 1,
          label: 'Gia hạn học đào tạo',
        },
        {
          id: 4,
          label: 'Đánh Fail đào tạo',
        },
        {
          id: 3,
          label: 'Xóa bản ghi',
        },
      ],
      statusCandidate: [
        {
          id: 0,
          label: 'Chưa đào tạo',
        },
        {
          id: 1,
          label: 'Đang học đào tạo',
        },
        {
          id: 2,
          label: 'Bị loại',
        },
        {
          id: 3,
          label: 'Hoàn thành đào tạo',
        },
      ],
      dialogVisible: false,
      dialogAddMore: false,
      dataTable: {
        id: '',
        name: '',
        phone: '',
        khuvuc_id: '',
        email: '',
        password: '',
        confirm_password: '',
        chi_nhanh_id: '',
        dataUpdate: false,
        type: 0,
      },
      branch_id: '',
      listArea: '',
      branch: '',
      listCandidate: [],
      listBranch: {},
      currentPage: '',
      paging: {
        current_page: 1,
        per_page: 10,
        total: 0,
        link: {},
        total_pages: '',

      },
      test: '',
      checkboxProp: [],
      candidateActionValue: '',
      dataUpdate: false,
      updatePass: false,
      dialogDeleteCandidate: false,
      dialogFailCandidate: false,
      files_prop: {},
      logImport: false,
      dialogAddCan: false,
      listDataImport: {},
      dataImport: [
        {
          id: 1,
          value: 'ho_va_ten',
        },
        {
          id: 2,
          value: 'so_dien_thoai',
        },
        {
          id: 3,
          value: 'khu_vuc',
        },
        {
          id: 4,
          value: 'van_phong',
        },
        {
          id: 5,
          value: 'email',
        },
        {
          id: 6,
          value: 'mat_khau',
        },
      ],
      listData: ['ho_va_ten', 'so_dien_thoai', 'khu_vuc', 'van_phong', 'email', 'mat_khau', 'is_import'],
      errorImport: {},
      area: {
        id: '',
        name: '',
        type_id: ''
      },
      checkStatusFilter: false,
      listPass: [],
      countError: 0,
      passNow: false,
      all: false,
      candidate: {},
      check: false,
      candidate_prop: {},
      prop: '',
      dialog: false,
      checkboxAttendance: [],
      course: '',
      filterbranch: '',
      candidateAttendance: {},
      closeAttendance: false,
      checkBoxClose: false,
      list_course_studying: {},
      messageerrorAttendance: '',
      checkData: false,
      listCourse: [],
      messageRequire: '',
      messageRequireBranch: '',
      courseCandidate: [],
      bgDeadline: '',
      loading: {
        table: false,
        search: false,
        save: false,
      },
      hiddenDropDown: false,
      candidateTypes: [],
      candidateTypesCentralArea: [
        {
          "label": "Sinh viên",
          "value": 0
        },
        { "label": "Học sinh",
          "value": 1
        },
      ],
      candidateTypesProvinceArea: [
        {
          "label": "Full time",
          "value": 0
        },
        { "label": "Part time",
          "value": 1
        },
        { "label": "Cộng tác viên",
          "value": 6
        },
      ],
      userExisted: {}
    }
  },
  mounted() {
    this.getUserLogin();
    this.getArea();
    this.getCourse();
  },
  methods: {
    getCandidateType() {
      if (this.area.type_id == 1) { // trung tâm
        this.candidateTypes = this.candidateTypesCentralArea
      }
      if (this.area.type_id == 2) { // tỉnh
        this.candidateTypes = this.candidateTypesProvinceArea
      }
    },
    pickStart() {

    },
    pickEnd() {

    },
    disabledStartDate(date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return date > new Date();
    },
    getCandidate() {
      let params = this.mergeParams();
      this.currentPage = 1;
      this.$store.dispatch(GET_CANDIDATE, params).then((response) => {
        this.listCandidate = response.data.candidate.data;
        this.paging.total = response.data.candidate.total;
        this.paging.per_page = response.data.candidate.per_page;
        this.paging.current_page = response.data.candidate.current_page;
        this.courseCandidate = response.data.course;
      })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
      };
      if (this.form.startDate) {
        params = _.merge(params, { startDate: this.form.startDate })
      }
      if (this.form.keyword) {
        params = _.merge(params, { keyword: this.form.keyword.trim() })
      }
      if (this.form.status || this.form.status == 0) {
        params = _.merge(params, { status: this.form.status })
      }
      if (this.form.branch_id) {
        params = _.merge(params, { branch_id: this.form.branch_id })
      }
      if (this.area.id) {
        params = _.merge(params, { area_id: this.area.id })
      }
      params = _.merge(params, customProperties);
      params = _.merge(params, { is_debt: 1 });
      return params;
    },
    exportExcelExample() {
      this.$store.dispatch(EXPORT_EXCEL, {}).then((response) => {
      })
    },
    getArea() {
      this.$store.dispatch(GET_AREA, {}).then((response) => {
        this.listArea = response;
      })
    },
    changeBranch() {
      this.$store.dispatch(GET_BRANCHES_BY_AREAS, { area_id: this.area.id }).then((response) => {
        this.branch = response.data;
        this.listBranch = response.data;
      })
    },
    getAllBranch() {
      this.$store.dispatch(GET_BRANCHES_ALL, {}).then((response) => {
        this.listBranch = response.data.data;
      })
    },
    hanldSubmit() {
      this.dataTable.khuvuc_id = this.area.id;
      this.$store.dispatch(CREATE_CANDIDATE, { data: { ...this.dataTable, id: '' } }).then((response) => {
        if (response) {
          this.candidate_prop = response;
          this.$message({
            type: 'success',
            message: 'Thêm mới ứng viên thành công',
            showClose: true
          })
          this.dialogVisible = false;
          this.getCandidate();
          this.changeBranch();
        }
      }).catch((res) => {
        if (res?.data?.data.message_validate_form) {
          this.$refs.form.setErrors(res.data.data.message_validate_form);
        }
      })
    },

    hanldSubmitUpdate() {
      if (this.updatePass && !this.dataUpdate) {
        this.dataTable.password = '';
        this.dataTable.confirm_password = '';
      } else {
        this.dataTable.dataUpdate = true;
      }
      this.$store.dispatch(UPDATE_CANDIDATE, { data: this.dataTable }).then((response) => {
        if (response) {
          this.branch = response.data;
          this.$message({
            type: 'success',
            message: 'Bạn đã cập nhật ứng viên thành công',
            showClose: true
          })
          this.dialogVisible = false;
          this.getCandidate();
          this.changeBranch();
        }
      }).catch((response) => {
        if (response?.data?.data.message_validate_form) {
          this.$refs.form.setErrors(response.data.data.message_validate_form);
        }
      })
    },
    listStatus(status) {
      let text;
      let bg;
      switch (status) {
        case 0:
          text = 'Chưa đào tạo';
          bg = 'bg-orange';
          break;
        case 1:
          text = 'Đang đào tạo';
          bg = 'bg-green';
          break;
        case 2:
          text = 'Bị loại';
          bg = 'bg-secondary';
          break;
        case 3:
          text = 'Hoàn thành đào tạo';
          bg = 'bg-blue';
          break;
      }
      return {
        text: text,
        bg: bg
      };
    },
    handleCurrentChange(val) {
      this.getCandidate({ page: val });
    },
    deleteCandidate() {
      this.$store.dispatch(DELETE_CANDIDATE, {
        candidateActionValue: this.candidateActionValue,
        checkboxProp: this.checkboxProp
      }).then((response) => {
        if (response.data == false) {
          this.$message({
            type: 'error',
            message: 'Không thể xóa các bản ghi có trạng thái Fail đào tạo hoặc Pass',
            showClose: true
          })
        } else {
          this.$message({
            type: 'success',
            message: response.message,
            showClose: true
          });
          this.checkboxProp = [];
        }
        this.dialogDeleteCandidate = false;
        this.candidateActionValue = '';
        this.getCandidate();
      })
    },
    changeStatusCandidate() {
      if (this.checkboxProp.length) {
        if (this.candidateActionValue == 4) {
          this.dialogFailCandidate = true;
          return;
        }
        if (this.candidateActionValue == 3) {
          this.dialogDeleteCandidate = true;
          return;
        }
        else {
          this.checkStatus(this.candidateActionValue, this.checkboxProp);
          this.updateStatusCandidate();
        }
      }
    },
    updateStatusCandidate() {
      this.$store.dispatch(UPDATE_STATUS_CANDIDATE, {
        candidateActionValue: this.candidateActionValue,
        checkboxProp: this.checkboxProp
      }).then((response) => {
        if (response.data == false) {
          this.$message({
            type: 'error',
            message: 'Tính năng chỉ áp dụng cho ứng viên Fail đào tạo.',
            showClose: true
          })
        } else if (response.data == 'no_update') {
          this.$message({
            type: 'error',
            message: 'Tính năng chỉ áp dụng cho ứng viên học đào tạo.',
            showClose: true
          })
        } else {
          this.getCandidate();
          this.$message({
            type: 'success',
            message: 'Cập nhập trạng thái thành công',
            showClose: true
          });
          this.checkboxProp = [];
          this.dialogFailCandidate = false;
          this.dialogDeleteCandidate = false;
        }
        this.candidateActionValue = '';
      })
    },
    checkStatus(action, status) {
      if (action) {

      }
    },
    updateCandidate(id) {
      this.$store.dispatch(GET_CANDIDATE_BY_USER_ID, { id: id }).then((response) => {
        this.dataTable.id = id;
        this.dataUpdate = response.data;
        this.dataTable.name = response.data.user ? response.data.user.name : '';
        this.dataTable.phone = response.data.user ? response.data.user.phone : '';
        this.dataTable.email = response.data.user ? response.data.user.email : '';
        this.dataTable.khuvuc_id = response.data.profiles && response.data.profiles.area ? response.data.profiles.area.id : '';
        this.dataTable.chi_nhanh_id = response.data.profiles && response.data.profiles.branch ? response.data.profiles.branch.id : '';
        this.dataTable.type = response.data.type;
        this.dialogVisible = true;
        this.dataUpdate = false;
        this.updatePass = true;
        this.check = true;
      })
    },
    modalAdd() {
      this.dataTable.name = '';
      this.dataTable.phone = '';
      this.dataTable.email = '';
      this.dataTable.khuvuc_id = '';
      this.dataTable.chi_nhanh_id = '';
      this.dataTable.type = 0;
      this.dialogVisible = true;
      this.dataUpdate = true;
      this.updatePass = false;
      this.check = false;
    },
    changeExcelExample(event) {
      this.files_prop = event.target.files ? event.target.files[0] : null;
      this.addFile = true;
    },
    myCallback(quantity) {
      return quantity;
    },
    async importFile() {
      if (!this.addFile) {
        return;
      }
      this.listDataImport = [];
      await this.fileToJson(this.files_prop).then(dataToImport => {
        this.logImport = true;
        this.listDataImport = dataToImport;
        this.validateData(dataToImport);
      })
        .catch(e => {
          this.$message({
            type: 'error',
            message: 'Có lỗi xảy ra',
            showClose: true
          });
        });
    },
    validateData(dataToImport) {
      this.loadingConfirm = true;
      this.listDataImport = this.listDataImport.map((data) => {
        return {
          ...data,
          error: [
            this.listDataImport.filter(c => c.so_dien_thoai == data.so_dien_thoai).length > 1 ? "Trùng số điện thoại" : null,
            this.listDataImport.filter(c => c.email == data.email).length > 1 ? "Trùng email" : null,
            data?.so_dien_thoai ? null : "Số điện thoại không được để trống.",
            this.validatePhone(data?.so_dien_thoai) ? null : "Số điện thoại không hợp lệ" ,
            data?.email ? null : "Email không được để trống.",
            data?.van_phong ? null : "Văn phòng không được để trống.",
            data?.ho_va_ten ? null : "Họ và tên không được để trống.",
            ((data?.mat_khau ?? '') + '').length >= 6 ? null : "Mật khẩu phải có độ dài lớn hơn hoặc bằng 6",
            ["học sinh", "sinh viên/người đi làm", "cộng tác viên"].includes((data.loai_ung_vien).toLowerCase().trim()) ? null : data.loai_ung_vien + ": Sai loại ứng viên",
          ],
        }
      });
      this.$store.dispatch(GET_DATA_IMPORT, dataToImport).then((response) => {
        let errorImport = response.data;
        this.listDataImport = this.listDataImport.map((data) => {
          var errorData = errorImport.find(d => d.so_dien_thoai == data.so_dien_thoai);
          return {
            ...data,
            branch_id: errorData?.branch_id,
            error: [
              ...data.error,
              errorData?.phone_error ? errorData?.phone_error : null,
              errorData?.email_error ? errorData?.email_error : null,
              errorData?.khu_vuc_error ? errorData?.khu_vuc_error : null,
              errorData?.branch_id > 0 ? null : "Không tìm thấy chi nhánh "+ data.van_phong,
            ],
            success: errorData?.phone_error == null && errorData?.email_error == null && errorData?.khu_vuc_error == null && errorData?.branch_id > 0
            && data?.error?.every((err, index) => index <= 8 ? !err : true),
            is_import: true
          }
        });
        this.loadingConfirm = false;

      }).catch((res) => {
        this.loadingConfirm = false;
        console.log(res, "err GET_DATA_IMPORT");
      });
    },

    validatePhone(phone) {
        if (!/^[0-9]+$/.test(phone)) {  
          return false;  
        }
        return true
      },
   
    importDataExcel(dataToImport) {
      this.loadingConfirm = true;
      return this.$store.dispatch(IMPORT_EXCEL, dataToImport).then((response) => {
        this.loadingConfirm = false;
        this.passNow = true;
        this.getCandidate();
      }).catch((res) => {
        this.loadingConfirm = false;
      })
    },
    confirmImportData() {
      if (this.invalidateDataImport) {
        return;
      }
      this.importDataExcel(this.listDataImport).finally(d => this.cancelImport());
    },
    closeFile() {
      this.$refs.myFileInput.value = '';
      this.addFile = false;
    },
    cancelImport() {
      this.logImport = false;
      this.dialogAddMore = false;
      this.closeFile();
    },
    exportExcelCandidate() {
      let params = this.mergeParams();
      this.$store.dispatch(EXPORT_EXCEL_CANDIDATE, params).then((response) => {

      })
    },
    getUserLogin() {
      let check = true;
      this.currentUser.user_positions.find((item) => {
        if (TRAINER.includes(item.position)) {
          check = false;
        }
      });
      this.checkTrainer = check;

      this.$store.dispatch(GET_USER_LOGIN, {}).then((response) => {
        this.area.id = response.data && response.data.area ? response.data.area.id : (response.data.branch ? response.data.branch.area_id : '');
        this.area.name = response.data && response.data.area ? response.data.area.name : (response.data.branch && response.data.branch.area ? response.data.branch.area.name : '');
        this.area.type_id = response.data && response.data.area ? response.data.area.type_id : (response.data.branch && response.data.branch.area ? response.data.branch.area.type_id : '');
        this.dataTable.khuvuc_id = response.data && response.data.area ? response.data.area.id : (response.data.branch ? response.data.branch.area_id : '');
        this.getCandidate();
        this.changeBranch();
        this.getCandidateType();
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Danh sách", icon: 'far fa-sitemap' },
          { title: "Danh sách ứng viên " + this.area.name, icon: 'far fa-chart-bar' }
        ]);
      })
    },
    formatDateTimeVietnam(value) {
      if (value) {
        let date = this.$moment(String(value)).format('HH:mm:ss DD-MM-YYYY')
        if (date === 'Invalid date') {
          return 'Không có thông tin';
        }
        return date;
      }
    },
    statusFilter() {
      if (this.checkboxProp.length) {
        this.checkStatusFilter = true;
      } else {
        this.checkStatusFilter = false;
      }
      this.all = this.checkboxProp.length == this.listCandidate?.length;
    },
    checkPass(email) {
      let pass = this.listPass.includes(email);
      return pass;
    },
    closeCheckbox() {
      this.checkboxProp = [];
      this.all = false;
      this.checkStatusFilter = false;
      this.candidateActionValue = '';
    },
    checkBoxAll() {
      this.changeAllCheckbox(this.listCandidate)
    },
    changeAllCheckbox(value) {
      let arr = [];
      if (this.all) {
        this.currentPage = 1;
        value.map(function (val) {
          arr.push(val?.candidate?.id)
        })
        this.checkboxProp = arr;
        this.checkStatusFilter = true;
      } else {
        this.checkStatusFilter = false;
        this.checkboxProp = [];
      }
    },
    changeAttendance() {

    },
    getCandidataAttendance() {
      this.$store.dispatch(GET_CANDIDATE_ATTENDANCE, {
        course: this.course,
        branch: this.filterbranch,
        area: this.area.id
      }).then((response) => {
        this.candidateAttendance = response.data;
        this.checkData = true
      })
    },
    attendance() {
      let action = 2;
      if (!this.checkboxAttendance.length) {
        this.messageerrorAttendance = 'Cần điểm danh ít nhất một ứng viên.';
        return;
      }
      if (!this.course || !this.filterbranch) {
        if (!this.course) {
          this.messageRequire = 'Khóa học không được để trống.';
        }
        if (!this.filterbranch) {
          this.messageRequireBranch = 'Văn phòng không được để trống';
        }
        return;
      }

      this.messageRequire = '';
      this.$store.dispatch(UPDATE_STATUS_CANDIDATE, {
        idCourse: this.course,
        candidateActionValue: action,
        checkboxProp: this.checkboxAttendance
      }).then((response) => {
        if (response.data == false) {
          this.$message({
            type: 'error',
            message: 'Tính năng chỉ áp dụng cho ứng viên Fail đào tạo.',
            showClose: true
          })
        } else if (response.data == 'no_update') {
          this.$message({
            type: 'error',
            message: 'Tính năng chỉ áp dụng cho ứng viên học đào tạo.',
            showClose: true
          })
        } else {
          // this.getCandidate();
          this.$message({
            type: 'success',
            message: 'Cập nhập trạng thái thành công',
            showClose: true
          });
          this.checkboxAttendance = [];
          this.getCandidataAttendance();
          if (this.checkBoxClose) {
            this.dialog = false;
          }
        }
      })
    },
    getCourse() {
      this.$store.dispatch(GET_LIST_COURSE_BY_AREA, {}).then((response) => {
        this.listCourse = (response && response.data) ?? [];
      }).catch((response) => {
      })
    },
    deadlineCandidate(deadline, id) {
      let now = this.$moment();
      let bg = '';
      let deadline_moment = this.$moment(deadline);
      let between = deadline_moment.diff(now);
      let data = 'Còn ' + deadline_moment.diff(now, 'days') + ' ngày';
      if (between < 0) {
        data = '';
        bg = '';
      } else {
        bg = 'bg-pink';
      }
      if (between !== between) {
        data = 'Không có thông tin .';
        bg = 'bg-pink';
      }
      this.bgDeadline = 'bg-pink';
      return {
        data: data,
        bg: bg
      };
    },
    changeStatus(status) {
      let text;
      let color;
      switch (status) {
        case 0:
          text = 'Chưa học';
          color = 'bg-secondary';
          break;
        case 1:
          text = 'Đang học';
          color = 'bg-orange';
          break;
        case 2:
          text = 'Đã học';
          color = 'bg-blue';
          break;
        case 3:
          text = 'Hoàn thành';
          color = 'bg-green';
          break;
      }
      return { text: text, color: color };
    },
    changeCourse(user_id) {
      let data = [];
      let course = this.courseCandidate.find((d) => d.userId == user_id);
      if (!course) {
        return data;
      }
      data = this.listCourse?.map((d) => course.courses.find(c => c.id == d.id) ?? {});
      return data;
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
         
          const ws = wb.Sheets[wsname]
          
          /* Convert array of arrays */
          const data = utils.sheet_to_json(ws)
          /* Update state */
          res(data.filter(d => {
            var size = Object.keys(d).length;
            if(size >= 2) {
                return { ...d, uid: uuidv4(), so_dien_thoai: this.repairPhone(d.so_dien_thoai) };
            }
          }));
          const header = data.shift()
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file)
      });
    },
    repairPhone(phone) {
      phone = ((phone ?? "") + "").trim();
      if (phone.length >= 10 && phone[0] == "0") {
        return phone;
      }
      return "0" + phone;
    },
    phoneNumberChange(){
      this.$store.dispatch(GET_USER_CANDIDATE, {phone:this.dataTable.phone}).then((response) => {
        if(response.data.account_type_id == STUDENT || response.data.thoi_gian_nghi_viec != null) {
          this.userExisted = response.data;
          this.checkUserExisted = Object.values(response.data).length ? true : false;
          this.dataTable.name = this.userExisted.name;
          this.dataTable.email = this.userExisted.email;
        }
        else{
          this.userExisted={};
          this.checkUserExisted = false;
          this.$message({
            type: 'error',
            message: 'Số điện thoại này đã được sử dụng',
            showClose: true
          });
        }
      }).catch((response) => {
        this.checkUserExisted = false;
        this.userExisted = null;
        this.dataTable.name = "";
        this.dataTable.email = "";
      })
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser']),
    invalidateDataImport() {
      return this.listDataImport.find(d => d.error.find(c => c != null));
    }
  },
}
</script>

<style scoped>
.el-select.w-full {
  width: 100%;
}

.bg-blue {
  background: #0a6ebd;
}

.bg-orange {
  background: orange;
}

.text-red {
  color: red;
}

.bg-da {
  background: #dcdfe6;
}

.text-green {
  color: #0dad0d;
}

.padding_td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.bg-green {
  background: #0dad0d;
}

.bg-violet {
  background: blueviolet;
}

input.el-input__inner {
  background: blueviolet;
}

.bg-da {
  background: #DCDFE6;
}

.bg-pink {
  background: pink;
}

.el-dropdown {
  vertical-align: top;
}

.el-dropdown+.el-dropdown {
  margin-left: 15px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.table-responsive {
  width: 100% !important;
  margin-left: 0px;
}

/*test drop dow*/
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 10;
  margin-left: -70%;
  background: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 1024px) {
  .dateSize {
    width: 220px;
  }
}
</style>
